import unfetch from 'unfetch';
import * as eventBus from './event-bus';
import {loadFluidImports} from './fluid-imports';
import {buildDependencyGraph} from './dependency-sets';

const $doc = window.document;
const $container = $doc.getElementById('framework-container');
const $uiModuleStyles = $doc.getElementById('ui-module-styles');
let envConfigLoaded = false;
let currentFramework = null;

const psFuncSeries = funcArr => funcArr.reduce(
	(p, next) => p.then(next),
	Promise.resolve()
);

const assetBaseUrls = { url: '', jsdelivr: 'https://cdn.jsdelivr.net' };
const assetCfgToUrls = (cfg, basePaths) => cfg.resources.reduce((acc, r, i, a) => {
	const basePath = basePaths[cfg.source];
	if (cfg.source === 'jsdelivr') {
		if (acc.length === 0) {
			acc.push(`${basePath}`);
			if (a.length > 1) { acc[0] += '/combine'; }
		}
		acc[0] += (i === 0 ? '/' : ',');
		acc[0] += r;
	} else {
		acc.push(`${basePath}/${r}`);
	}
	return acc;
}, []);

export function loadEnvConfig() {
	if (envConfigLoaded !== false) { return; }
	envConfigLoaded = true;
	loadScript(`${window.ss.env.tools['env-config']}/env-config-bootstrap.js`);
}

export function getManifestEntry(mod) {
	const { manifest } = window.ss.env;
	const overrideUrl = localStorage.getItem(`assets.uiModules.${mod.bootstrapName}`);
	const isLegacyLocalDev = overrideUrl && overrideUrl.startsWith('https://localhost:3100');
	const codeSuffix = isLegacyLocalDev ? '.compiled' : '';
	return (
		overrideUrl != null
		? {
			path: {
				app: `${overrideUrl}/app${codeSuffix}.js`,
				css: `${overrideUrl}/app${codeSuffix}.css`,
				routeData: `${overrideUrl}/route-data.js`,
				config: `${overrideUrl}/ui-module-config.json`,
			}
		}
		: manifest[mod.bootstrapName]
	);
}

export function loadUiModuleAssets(mod) {
	const manifestEntry = getManifestEntry(mod);
	let modFwName, modDepSets, modFluidImports; //modConfig

	return getUiModuleConfig(manifestEntry)
		.then(uiModCfg => {
			// modConfig = uiModCfg;
			modFwName = uiModCfg.framework || 'angularjs';
			modDepSets = uiModCfg.dependencySets || [];
			modFluidImports = uiModCfg.fluidImports || [];
		})
		.then(() => Promise.all([
			buildDependencyGraph(modDepSets, modFwName),
			loadFluidImports(modFluidImports),
			loadFrameworkAssets(modFwName)
		]))
		.then(([assetGroups]) => psFuncSeries(
			assetGroups.map(g => () => Promise.all(g.map(
				url => loadAsset(url.split('.').slice(-1)[0], url)
			)))
		))
		.then(() => {
			if (manifestEntry == null) { return; }
			$uiModuleStyles.setAttribute('href', manifestEntry.path.css);
			return loadAsset('js', manifestEntry.path.app);
		})
		.then(() => eventBus.fire('ui-module-activate', mod));
}

export function getUiModuleConfig(mani) {
	if (mani == null) {
		return Promise.resolve({
			framework: 'angularjs',
			dependencySets: []
		});
	}
	if (mani && mani.config) {
		return Promise.resolve(mani.config);
	} else {
		return unfetch(`${mani.path.config}`).then(res => res.json());
	}
}

export function loadFrameworkAssets(fwName) {
	if (currentFramework === fwName) { return Promise.resolve(); }
	const { frameworks } = window.ss.env;
	const fwBaseUrl = frameworks[fwName];
	window.ss.env.fwBaseUrl = fwBaseUrl;
	return unfetch(`${fwBaseUrl}/framework.json`)
		.then(res => res.json())
		.then(fw => psFuncSeries(fw.assets.map((bundle, i) => {
			return () => Promise.all(bundle.map(a => {
				const assetUrls = assetCfgToUrls(a, Object.assign({}, assetBaseUrls, {build: fwBaseUrl}));
				return Promise.all(assetUrls.map(url => loadAsset(a.type, url)));
			}));
		})))
		.then(() => {currentFramework = fwName;});
}

const loadedAssets = [];
export function loadAsset(type, url) {
	if (loadedAssets.includes(url)) { return Promise.resolve(); }
	loadedAssets.push(url);

	if (type === 'css') {
		return loadStylesheet(url);
	} else if (type === 'js') {
		return loadScript(url);
	} else if (type === 'html') {
		return loadMarkup(url);
	}
}

export function loadMarkup(url) {
	return unfetch(url)
		.then(res => res.text())
		.then(html => $container.innerHTML += html);
}

export function loadScript(url, attrs = {}) {
	return new Promise((resolve, reject) => {
		const scriptAttrs = Object.assign({
			'charset': 'utf-8',
			'type': 'text/javascript'
		}, attrs);
		const el = injectTag('script', scriptAttrs);
		el.onload = () => resolve();
		el.onerror = () => reject();
		el.src = url;
	});
}

export function loadStylesheet(url, attrs = {}) {
	const linkAttrs = Object.assign({
		'href': url, 'charset': 'utf-8',
		'rel': 'stylesheet', 'media': 'all'
	}, attrs);
	injectTag('link', linkAttrs);
	return Promise.resolve();
}

function injectTag(tagName, attrs, target) {
	const el = $doc.createElement(tagName);
	Object.keys(attrs).forEach(attr => el.setAttribute(attr, attrs[attr]));
	$doc.body.appendChild(el);
	return el;
}
