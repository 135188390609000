
export function applyOverrideState(stateObj) {
	// clear out existing overrides
	Object.keys(localStorage)
		.filter(k => k === 'env' || k.startsWith('buildConfig.') || k.startsWith('assets.'))
		.forEach(k => localStorage.removeItem(k));

	// apply the override state
	Object.keys(stateObj)
		.forEach(k => localStorage.setItem(k, stateObj[k]));

	// copy manifest data from localStorage (for ng-framework)
	const localManifest = localStorage.getItem('manifest');
	if (localManifest != null) {
		sessionStorage.setItem('manifest', localManifest);
	} else {
		sessionStorage.removeItem('manifest');
	}
}
