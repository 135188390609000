import { loadEnvConfig } from './asset-loader';

export const showOverrideFooter = ({env, assets, buildConfig}) => {
	if (env == null && Object.keys(assets).length === 0 && !buildConfig) { return; }
	const $el = window.document.getElementById('override-footer');
	const assetKeys = Object.keys(assets);
	const assetText = (
		assetKeys.length
		? ` | ${assetKeys.map(k => `${k}: ${assets[k]}`).join(' | ')}`
		: ''
	);
	const buildConfigText = (!!buildConfig
		? ` | env variables: ${buildConfig}`
		: ''
	);
	$el.innerText = `env: ${env || 'production'}${assetText}${buildConfigText}`;
	$el.style.display = '';
	$el.addEventListener('click', loadEnvConfig, {once: true});
};
