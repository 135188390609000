
export function getQueryParamValue(name) {
	const re = new RegExp(`(?:&|(\\?))${name}=([\\w%@:*+-./]*)`);
	const result = window.location.search.match(re);
	if (result != null) {
		return window.decodeURIComponent(result[2]);
	} else {
		return null;
	}
}

export function removeQueryParam(name) {
	const re = new RegExp(`(?:&|(\\?))${name}=([\\w%@:*+-./]*)`);
	const redirectUrl = window.location.href // ec-dashboard/?paramName=stage&currentOrg=20001
		.replace(re, '$1') // => ec-dashboard/?&currentOrg=20001
		.replace(/(\?)&/g, '$1') // => ec-dashboard/?currentOrg=20001
		.replace(/\?$/, ''); // ec-dashboard/? => ec-dashboard/

	if (window.history && window.history.replaceState) {
		window.history.replaceState('', document.title, redirectUrl);
	} else {
		window.location.href = redirectUrl;
	}
}
