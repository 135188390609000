/* globals sidekick */

import * as eventBus from '../utils/event-bus';

const $404 = document.getElementById('page-error-404');

eventBus.on('page-error-404', function () {
	$404.style.display = '';
	sidekick.events.emit('pick-org-app.abort');
	sidekick.events.emit('header.visible', false);
	sidekick.events.emit('nav.visible', false);
	const routeNotFound = window.location.href;
	const removeRouteChangeListener = eventBus.on('route-change', function (to, from) {
		if (from === routeNotFound && to !== routeNotFound) {
			sidekick.events.emit('header.visible', true);
			sidekick.events.emit('nav.visible', true);
			$404.style.display = 'none';
			removeRouteChangeListener();
		}
	});
});
