
export function getUiModDetails(url) {
	const urlValue = getModUrlValue(url);
	const {manifest} = window.ss.env;
	// default details to return if our lookup fails for any reason
	const details = {
		isValid: false,
		isUniversal: false,
		bootstrapName: null,
		productId: null,
		urlValue,
	};

	// no module is defined
	if (urlValue == null || manifest == null) { return details; }
	if (urlValue.length === 0) { return details; }

	// calculate universal module name (so we can get for it)
	const productId = urlValue.split('-')[0];
	const universalModName = urlValue.replace(productId, 'universal');

	// manifest entries for module and universal module variant
	const modMani = manifest[urlValue];
	const uniMani = manifest[universalModName];

	// check for exact match as well as universal module
	const isUniversal = uniMani != null;
	const isValid = modMani != null || uniMani != null;

	Object.assign(details, {
		bootstrapName: (isUniversal ? universalModName : urlValue),
		isValid, isUniversal, productId
	});

	return details;
};

export function getModUrlValue(url = window.location.href) {
	const loc = window.document.createElement('a');
	loc.setAttribute('href', url);
	let routePath = loc.pathname;
	if (process.env.IDP === 'morpheus') {
		const routePaths = loc.pathname.split('/');
		routePath = routePaths.length > 3 && routePaths[3] ? `${routePaths[2].split(':')[0]}-${routePaths[3]}` : '';
		return routePath;
	}
	var match = routePath.match(/^\/?([\w-]*)/i);
	if (match != null && match.length === 2) {
		return match[1];
	} else {
		return '';
	}
}
