import {loadAsset} from './asset-loader';
import {getUiModDetails} from './ui-module-helper';

const moduleRegistry = new Map(); // url -> module
const uiModuleFluidMap = new Map(); // uiModuleName -> {assetName: module}

const psFuncSeries = funcArr => funcArr.reduce(
	(p, next) => p.then(next),
	Promise.resolve()
);

const outstandingLoadRequests = {};
window.define = mod => {
	const payload = mod();
	const {version, name, stylesheet} = payload;
	if (outstandingLoadRequests[`${version}/${name}`] == null) {
		throw Error(`FLUID LOAD ERR: ${version}/${name} could not be mapped`);
	}
	const {url, key, resolve} = outstandingLoadRequests[`${version}/${name}`];
	if (stylesheet != null) {
		const styleUrl = `${url.substr(0, url.lastIndexOf('/'))}/${stylesheet}`;
		loadAsset('css', styleUrl);
	}
	moduleRegistry.set(url, payload);
	const cmptMap = uiModuleFluidMap.get(getUiModDetails().bootstrapName);
	cmptMap[key] = payload;
	resolve();
	delete outstandingLoadRequests[`${version}/${name}`];
};

export function loadFluidImports(list) {
	const mod = getUiModDetails();
	const cmptMap = {};
	uiModuleFluidMap.set(mod.bootstrapName, cmptMap);

	// location components which are loaded using different names
	const hasDuplicates = (v, i, a) => a.indexOf(v) !== i;
	const cmptUrlList = list.flatMap(
		v => Object.entries(v.components).map(
			([, c]) => `${v.location}/${c.version}/${c.name}`
		)
	);
	if (cmptUrlList.some(hasDuplicates)) {
		const dupCmpt = cmptUrlList.find((v, i, a) => a.indexOf(v) !== i);
		throw Error(`FLUID LOAD ERR: A component by any other name, stinks. (DUPLICATE: ${dupCmpt})`);
	}

	return psFuncSeries(list.map(imp => () => {
		const {location: loc, components} = imp;
		const imports = Object.entries(components)
			.map(([key, value]) => Object.assign(value, {
				key, url: `${loc}/${value.version}/${value.name}.js`
			}))
			.filter(i => {
				// if the module has been previously loaded, get it from the registry
				if (moduleRegistry.has(i.url)) {
					cmptMap[i.key] = moduleRegistry.get(i.url);
					return false;
				}
				return true;
			});

		// create promises that let us know once each component has been
		// registered with the AMD loader.
		const loadRequestsComplete = Promise.all(
			imports.map(i => new Promise((resolve, reject) => {
				outstandingLoadRequests[`${i.version}/${i.name}`] = Object.assign({resolve}, i);
			}))
		);

		// load the assets and wait for them to be registered (10 second timeout)
		return Promise.all(imports.map(i => loadAsset('js', i.url)))
			.then(() => Promise.race([
				loadRequestsComplete,
				new Promise((r, reject) => setTimeout(reject, 10000, Error('timeout')))
			]));
	}));
};

export function getFluidImports() {
	const moduleName = getUiModDetails().bootstrapName;
	if (!uiModuleFluidMap.has(moduleName)) {
		throw Error(`No FluidImports for "${moduleName}"`);
	}
	return uiModuleFluidMap.get(moduleName);
}
