import unfetch from 'unfetch';

const loadedDepSets = {};
let fwBaseUrl;

export function buildDependencyGraph(modDepSets, modFwName) {
	if (fwBaseUrl == null) {
		const { frameworks } = window.ss.env;
		fwBaseUrl = frameworks[modFwName];
	}
	let depGraphData = [];
	return Promise.all(
		modDepSets.map(dsName => loadGraph(dsName, depGraphData))
	).then(() => {
		const assetGroups = [];
		const depSetCfgToUrls = (acc, g) => {
			acc.push(`${fwBaseUrl}/dependency-sets/${g.name}.js`);
			if (g.styles) {
				acc.push(`${fwBaseUrl}/dependency-sets/${g.name}.css`);
			}
			return acc;
		};
		while (!!depGraphData.length) {
			const group = depGraphData.filter(d => d.waitingOn.length === 0);
			if (group.length === 0) {
				throw Error(`Circular dependency found: ${group.map(g => g.name).join(', ')}`);
			}
			assetGroups.push(group.reduce(depSetCfgToUrls, []));
			const groupAssets = group.map(a => a.name);
			depGraphData = depGraphData
				.filter(d => !group.includes(d))
				.map(d => Object.assign(d, {
					waitingOn: d.waitingOn.filter(wo => !groupAssets.includes(wo))
				}));
		}
		return assetGroups;
	});
}

// adds dependency graph data to state
function loadGraph(dsName, depGraphData) {
	if (loadedDepSets.hasOwnProperty(dsName)) {return Promise.resolve();}
	if (depGraphData.find(e => e.name === dsName) != null) { return Promise.resolve(); }
	return unfetch(`${fwBaseUrl}/dependency-sets/${dsName}.json`)
		.then(res => res.json())
		.then(res => {
			if (depGraphData.find(e => e.name === dsName) != null) { return Promise.resolve(); }

			loadedDepSets[dsName] = res;
			const {dependencySets = [], styles} = res;
			const waitingOn = dependencySets
				.filter(name => !loadedDepSets.hasOwnProperty(name));

			depGraphData.push({name: dsName, waitingOn, styles: !!styles});
			if (waitingOn.length) {
				return Promise.all(
					waitingOn.map(name => loadGraph(name, depGraphData))
				);
			}
		});
}
